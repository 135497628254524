import React from "react"
// import GoogleMapReact from 'google-map-react';

import { MapContainer } from 'react-leaflet/MapContainer'
import { TileLayer } from 'react-leaflet/TileLayer'
import { Marker, Popup } from "react-leaflet"
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import 'leaflet/dist/leaflet.css'
import './MapBox.css'



function MapBox () {

    const myCustomColour = '#9B0C3E'

    const markerHtmlStyles = `
    background-color: ${myCustomColour};
    width: 2rem;
    height: 2rem;
    display: block;
    left: -1.5rem;
    top: -1.5rem;
    position: relative;
    border-radius: 3rem 3rem 0;
    transform: rotate(45deg);
    border: 1px solid #FFFFFF`

    let DefaultIcon = L.divIcon({
        className:"",
        iconUrl: icon,
        iconShadow: iconShadow,
        popupAnchor: [-5,-10],
        iconSize: [10,50],
        iconAnchor: [0,"3rem"],
        html: `<span style="${markerHtmlStyles}" />`
    });

    const mapToken = process.env.REACT_APP_MAP_TOKEN

    const mapPosition = [51.0436, -114.0635]
    const waxwellPostion = [51.043022, -114.057999]

    // <iframe title="mapBox" className = "mapBox" src="https://www.google.com/maps/d/embed?mid=1mstdAApNyTAv6M7z1zPtN3fWfcFxCv0&ehbc=2E312F&z=9" width='500px'></iframe> */}

    return(

        <MapContainer className = 'map' center = {mapPosition} zoom ={10}>
            <TileLayer url={`https://tile.jawg.io/jawg-streets/{z}/{x}/{y}{r}.png?access-token=${mapToken}`}/>
            <Marker position={waxwellPostion} icon = {DefaultIcon}>
                <Popup>
                    Halo Beauty Bar 
                    <br /> 
                    1014 1 St SE, Calgary, AB T2G 2G5
                </Popup>
            </Marker>
        </MapContainer>
        
    )
};

export default MapBox;