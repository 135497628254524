const HaloLogo = (props)=>{
    return (
        <svg version="1.0" 
        xmlns="http://www.w3.org/2000/svg"
        width="100%" 
        height="100%" 
        viewBox="0 0 761.000000 328.000000"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,328.000000) scale(0.100000,-0.100000)"
        fill={`${props.color}`} stroke="none">
        <path d="M2956 2783 c-49 -126 -113 -291 -143 -368 -30 -77 -75 -192 -100
        -255 -45 -115 -124 -317 -190 -485 -65 -169 -82 -210 -147 -378 -36 -93 -66
        -172 -66 -178 0 -5 25 -9 55 -9 53 0 55 1 74 38 10 20 22 48 26 62 8 26 77
        205 170 440 47 120 87 221 172 440 13 36 45 117 69 180 25 63 50 129 56 145
        19 52 25 49 58 -32 18 -43 41 -96 51 -118 10 -22 119 -278 244 -568 124 -290
        232 -541 240 -558 l16 -29 159 0 c95 0 160 4 160 10 0 8 -60 154 -110 265 -10
        22 -75 175 -145 340 -70 165 -143 336 -162 380 -29 65 -146 341 -361 843 -14
        34 -29 62 -32 62 -3 0 -46 -102 -94 -227z"/>
        <path d="M6210 2961 c-317 -59 -577 -257 -708 -540 -53 -115 -72 -199 -79
        -346 -12 -277 67 -494 249 -689 123 -133 282 -225 463 -272 91 -23 113 -25
        250 -21 176 6 250 23 393 94 170 83 305 213 405 388 148 259 156 598 21 868
        -54 107 -115 187 -214 280 -72 68 -109 94 -202 140 -147 74 -237 97 -403 102
        -71 2 -150 0 -175 -4z m286 -122 c93 -29 131 -49 203 -109 260 -216 351 -688
        212 -1092 -68 -198 -244 -378 -416 -423 -255 -68 -511 63 -644 328 -185 367
        -133 872 115 1136 146 155 348 216 530 160z"/>
        <path d="M350 2030 l0 -920 155 0 155 0 2 443 3 442 305 3 c168 2 374 0 458
        -3 l152 -7 0 -439 0 -439 155 0 155 0 0 914 0 914 -22 6 c-30 8 -252 8 -272 0
        -14 -5 -16 -52 -16 -420 l0 -414 -460 0 -460 0 0 414 c0 368 -2 415 -16 420
        -9 3 -78 6 -155 6 l-139 0 0 -920z"/>
        <path d="M4263 2943 c-10 -4 -13 -197 -13 -919 l0 -914 656 0 655 0 -3 53 -3
        52 -492 3 -493 2 0 853 c0 650 -3 856 -12 865 -12 12 -268 17 -295 5z"/>
        <path d="M350 420 l0 -230 125 0 c143 0 197 15 220 61 28 54 16 124 -28 159
        -19 16 -19 17 12 47 26 27 31 39 31 80 0 52 -9 70 -50 97 -20 13 -51 16 -167
        16 l-143 0 0 -230z m248 128 c7 -7 12 -21 12 -33 0 -36 -20 -45 -97 -45 l-73
        0 0 45 0 45 73 0 c43 0 78 -5 85 -12z m0 -180 c14 -14 15 -47 2 -68 -7 -11
        -28 -15 -82 -15 l-73 0 -3 48 -3 47 73 0 c44 0 79 -5 86 -12z"/>
        <path d="M1110 420 l0 -230 155 0 155 0 0 45 0 45 -105 0 -105 0 0 50 0 50
        105 0 105 0 0 45 0 45 -105 0 -105 0 0 45 0 45 105 0 105 0 0 45 0 45 -155 0
        -155 0 0 -230z"/>
        <path d="M1950 623 c-7 -16 -23 -59 -35 -98 -13 -38 -44 -128 -69 -199 -25
        -70 -46 -130 -46 -133 0 -2 23 -3 52 -1 51 3 52 4 65 45 17 49 36 56 142 51
        l63 -3 17 -45 c16 -45 17 -45 70 -48 43 -3 53 0 48 11 -3 8 -40 112 -83 231
        l-77 216 -67 0 c-63 0 -67 -2 -80 -27z m114 -157 c14 -44 23 -82 20 -85 -8 -8
        -102 -6 -111 2 -3 4 6 46 22 92 15 47 32 82 36 78 4 -4 19 -44 33 -87z"/>
        <path d="M2620 471 c0 -169 1 -180 23 -212 50 -74 132 -101 224 -74 59 18 100
        58 107 108 11 72 16 218 10 285 l-7 72 -43 0 -44 0 -1 -82 c-2 -178 -13 -269
        -33 -284 -28 -20 -79 -17 -110 7 l-26 20 0 170 0 169 -50 0 -50 0 0 -179z"/>
        <path d="M3370 605 l0 -45 65 0 65 0 0 -185 0 -185 45 0 45 0 0 185 0 184 68
        3 67 3 3 43 3 42 -180 0 -181 0 0 -45z"/>
        <path d="M4060 644 c0 -3 24 -49 53 -102 86 -160 96 -188 97 -274 l0 -78 50 0
        50 0 0 85 0 86 75 144 75 145 -59 0 -59 0 -38 -85 c-21 -47 -42 -84 -48 -82
        -5 2 -25 40 -45 85 l-36 82 -57 0 c-32 0 -58 -3 -58 -6z"/>
        <path d="M5290 420 l0 -230 130 0 c156 0 200 13 225 67 24 51 15 110 -23 144
        l-29 26 33 30 c28 25 34 37 34 72 0 52 -21 94 -55 109 -16 8 -80 12 -170 12
        l-145 0 0 -230z m258 128 c17 -17 15 -44 -4 -62 -11 -12 -35 -16 -85 -16 l-69
        0 0 45 0 45 73 0 c43 0 78 -5 85 -12z m0 -180 c19 -19 14 -55 -10 -72 -15 -11
        -45 -16 -85 -16 l-63 0 0 50 0 50 73 0 c43 0 78 -5 85 -12z"/>
        <path d="M6155 623 c-3 -15 -38 -115 -76 -224 -38 -108 -69 -199 -69 -203 0
        -3 22 -6 49 -6 l48 0 21 50 21 51 93 -3 92 -3 15 -47 15 -48 48 0 c32 0 48 4
        48 13 0 6 -34 110 -77 230 l-77 217 -72 0 c-70 0 -72 -1 -79 -27z m119 -158
        c14 -44 26 -83 26 -88 0 -4 -27 -7 -60 -7 -46 0 -60 3 -60 14 0 30 52 178 60
        170 4 -5 19 -45 34 -89z"/>
        <path d="M6840 420 l0 -230 50 0 50 0 0 85 c0 105 12 109 73 29 88 -118 85
        -116 151 -112 l59 3 -57 75 c-31 41 -56 78 -56 82 0 4 18 17 41 30 49 27 69
        62 69 118 0 57 -23 106 -62 130 -29 17 -50 20 -175 20 l-143 0 0 -230z m268
        128 c7 -7 12 -24 12 -39 0 -42 -32 -59 -112 -59 l-68 0 0 55 0 55 78 0 c47 0
        83 -5 90 -12z"/>
        </g>
        </svg>
  )
}

export default HaloLogo;