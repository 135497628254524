import React from "react";
import './TwoTextsComponent.scss'

const TwoTextsComponent = ({ text1, textColor, text2, }) => {
  
  const containerStyle = {
    display: "flex",
    justifyContent: "space-between",
    padding: "0.5rem",
  };

  const text1Style = {
    textAlign: "right",
    color: textColor,
    fontWeight: 100,
  };

  const text2Style = {
    textAlign: "left",
    color: textColor,
    fontWeight: 100,
  };

  return (
    <div className="twoTextContainer">
      <div style={containerStyle} className="innerTextContainer">
        <h3 style={text1Style}>{text1}</h3>
        <h3 style={text2Style}>{text2}</h3>
      </div>
    </div>
  );
};

export default TwoTextsComponent;