import React from "react"
import './Academy.scss'
import { StyledEngineProvider } from '@mui/material/styles';
import { Toolbar } from "@mui/material";
import AppbarHeader from "../../components/AppbarHeader/AppbarHeader";
import AccordianList from "../../components/AccordianList/AccordianList";
import UseIntersectionObserver from "../../components/UserIntersectionObserver";
import ContactForm from "../../components/Contact/ContactForm";
import CheckMark from "../../resources/Check";
import Copyright from "../../components/Copyright/Copyright";

function Academy () {

    const { targetRef, isVisible } = UseIntersectionObserver(null, {
        root: null,
        rootMargin: '0px',
        threshold: 0.01, // Adjust this threshold as needed
      });

    const { targetRef:targetRef2, isVisible:isVisible2 } = UseIntersectionObserver(null, {
    root: null,
    rootMargin: '0px',
    threshold: 0.01, // Adjust this threshold as needed
    });

    const mainBackgroundColor = '#e79796';
    const mainFontColor = '#000000';

    const advancedLipCourse = [
        "In person 1:1 training",
		"One day course",
		"Learn Halo’s Signature Lip Technique",
		"Detailed Manual",
		"Hands-on training with 5+ model"
    ]

    const shadowing = [
        "One day shift",
        "A specialized learning experience in the dynamic field of cosmetic injectables",
        "Learn about the latest injection techniques",
        "Gain a deeper understanding of client consultations",
        "Observe what a day with a skilled injector looks like",
        "Opportunity to ask questions about the field and procedures",
        "Ideal for both newcomers and experienced practitioners",
    ]

    const preceptorship = [
        "Hands-on experience to strengthen injecting skills by working directly with clients under the supervision of a senior nurse injector",
        "Learn signature Halo techniques, built off of experience from internationally renowned lip injectors",
        "Social media guidance",
        "Earn 10% commission",
        "12 hours | $1000",
        "24 hours | $1800",
        "36 hours | $2600",
    ]

  return (
    <div className="mainSectionAcademy">
        <StyledEngineProvider injectFirst>
        <AppbarHeader backgroundColor={mainBackgroundColor} textColor={mainFontColor}></AppbarHeader>
        <Toolbar></Toolbar>

        <div className="horizontalFlexAcademy">
        <div className="fillerImageAcademy">
        <img src={require('../../resources/filler_pictures/FullSizeRender-1.jpg')} alt="Injectablesfvf" />
        </div>

        <div ref={targetRef2} className={`AcademyComponent ${isVisible2 ? 'visible' : ''}`}>
            <div className="leftBox">
                <h2>Are you a medical professional with a real passion for beauty?</h2>
                <div className="checkMark">
                <CheckMark></CheckMark>
                </div>
            </div>

            <div className="rightBox">
                <div className="checkMark">
                    <CheckMark></CheckMark>
                </div>
                <h2>Someone with a creative spirit and a real knack for building relationships with people while helping them realize their vision?</h2>
            </div>

            <div className="centerBox">
                <h2>Well then a career in cosmetic injectables may be your calling!</h2>
            </div>
        </div>

        </div>

        <div className="fillerImageAcademy">
        <img src={require('../../resources/filler_pictures/IMG_0322.jpg')} alt="Injectablesfvf" />
        </div>

        <div ref={targetRef} className={`paragraph ${isVisible ? 'visible' : ''}`}>
            <h2>With a strong background in academia and over a decade of experience as an academic tutor, Nurse Michelle is naturally inclined towards the teaching discipline.</h2>
            <br></br>
            <h2>And as she has built her own competencies in the field of cosmetic injectables, Michelle has seen first hand the value in having a real teaching mindset when it comes to cosmetic skills training & development.</h2> 
            <br></br>
            <h2>With this in mind, the Halo Beauty Bar offers training programs that are fit to the student and built with 1:1 development at the core of each program. </h2>
        </div>

        <br></br>

        <br></br>

        <div className="fillerImageAcademy">
        <img src={require('../../resources/filler_pictures/IMG_0006.jpg')} alt="Injectablesfvf" />
        </div>

        <br></br>


        <h2 style = {{color: `${mainFontColor}`}}>Our Offerings</h2>

        {/* <TwoTextsComponent text1="Advanced Lip Course" text2="$2500 + GST" textColor={mainFontColor}/> */}

        <AccordianList title="Advanced Lip Course" title2="$3200" listSymbol="circle" listItems={advancedLipCourse} backgroundColor= {mainBackgroundColor} textColor = {mainFontColor}></AccordianList>


        {/* <TwoTextsComponent text1="Shadowing" text2="$750/shfit" textColor={mainFontColor}/> */}
        
        <AccordianList title="Shadowing" title2="$750/shift" listSymbol="circle" listItems={shadowing} backgroundColor= {mainBackgroundColor} textColor = {mainFontColor}></AccordianList>

        <AccordianList title="Preceptorship" title2="See Details" listSymbol="circle" listItems={preceptorship} backgroundColor= {mainBackgroundColor} textColor = {mainFontColor}></AccordianList>

        <br></br>
        <br></br>
        
        <div className="contactBox">

        <div className="fillerImageAcademy">
        <img src={require('../../resources/filler_pictures/IMG_0494.JPG')} alt="Injectablesfvf" />
        </div>
        
        <ContactForm></ContactForm>
        </div>
        
    </StyledEngineProvider>
    <div className="pageFooterAcademy">
        <Copyright></Copyright>
    </div>
    </div>
  )
};

export default Academy;