import React from "react"
import './Skin.scss'
import { StyledEngineProvider } from '@mui/material/styles';
import { Toolbar} from "@mui/material";
import TwoTextsComponent from "../../components/Price/TwoTextsComponent";
import AppbarHeader from "../../components/AppbarHeader/AppbarHeader";

import AccordianList from "../../components/AccordianList/AccordianList";
import Copyright from "../../components/Copyright/Copyright";

function Skin () {

    const mainBackgroundColor = "#E5BDBE";
    const mainFontColor = "#000000"

    const microNeedlingList = [
        "Microneedling, a minimally invasive and increasingly popular cosmetic procedure, effectively rejuvenates and tightens skin without surgery. Also known as collagen induction therapy, it involves tiny needles creating controlled micro-injuries on the skin's surface.", 
        "This process activates the body's natural healing, leading to cell renewal. With its ability to stimulate collagen and elastin production, essential for healthy skin structure, microneedling offers visible results with minimal discomfort and no significant downtime."
    ]

    const benefitsNeedling = [
        "Skin Rejuvenation: Revitalizes and refreshes skin appearance.",
        "Wrinkle Reduction: Helps in diminishing wrinkles and fine lines.",
        "Improved Skin Texture and Tone: Enhances overall skin texture and evens out skin tone.",
        "Scar Treatment: Effective in remodeling and reducing the appearance of scars.",
        "Stimulates Collagen Production: Boosts collagen and elastin formation, essential for skin elasticity and firmness.",
        "Minimizes Pore Size: Aids in reducing the size of enlarged pores.",
        "Treats Hyper-pigmentation: Effective in evening out skin pigmentation issues.",
        "Acne and Blemish Reduction: Helps in unclogging pores and reducing acne and blemishes."
    ]

    const goodCandidateList = [
        "Aging Skin or preventing signs of aging: Those wanting to reduce signs of aging like wrinkles and age spots.",
        "Scars and Hyper-pigmentation: Beneficial for treating scars and uneven skin tone.",
        "Seeking Skin Texture Improvement: Ideal for enhancing skin texture and elasticity.",
        "In Good Health: Suitable for individuals with no major health issues.",
        "Not Pregnant/Breastfeeding: Avoided during pregnancy and breastfeeding.",
        "Free from Certain Skin Conditions: Not recommended for active skin infections or severe eczema.",
        "Not on Specific Medications: Avoid if on medications that affect bleeding or skin sensitivity.",
        "Book a consult to confirm suitability."

    ]

    const vampireFacialList = ["A vampire facial, also known as a platelet-rich plasma (PRP) facial, is a cosmetic treatment that combines microneedling with the application of PRP. Here's how it typically works:", 
        "Blood Drawn: A small amount of the patient's blood is drawn, usually from the arm.",
        "Preparation of PRP: The drawn blood is then processed in a centrifuge to separate the plasma, which is rich in platelets. Platelets are components in the blood known for their healing properties and growth factors.",
        "Microneedling Procedure: The skin is first treated with microneedling, where fine needles create tiny punctures in the top layer of the skin. This process stimulates the skin's healing response and allows for deep penetration of the PRP.",
        "Application of PRP: The prepared PRP is then applied to the skin, where it penetrates through the tiny channels created by microneedling.",
        "Healing and Rejuvenation: The growth factors in the PRP help to stimulate cellular regeneration and collagen production, leading to rejuvenated, firmer, and more youthful-looking skin."]

    const benefitsVampire = [
        "Faster Healing: PRP accelerates healing post-microneedling.",
        "Greater Collagen Boost: Enhanced collagen production for more youthful skin.",
        "Superior Skin Rejuvenation: Improved texture and tone due to PRP's growth factors.",
        "More Effective Scar Reduction: Better at reducing scars, including acne scars.",
        "Enhanced Wrinkle Treatment: More effective in diminishing fine lines and wrinkles.",
        "Natural Approach: Uses the body's own platelets, offering a natural treatment method.",
        "Longer-lasting Results: Combination of PRP and microneedling may yield more durable effects."
    ]

    const beforeTreatment = [
        "Stop Harsh Skin Treatments: Avoid retinoids and exfoliants 3 days prior to treatment.",
        "No Laser or Peels: Refrain from these treatments 2 weeks before microneedling.",
        "Minimize Sun Exposure: Stay out of the sun for a week before and use sunscreen.",
        "Clean, Hydrated Skin: Ensure your skin is free of active infections or inflammations.",
        "No Waxing: Do not wax or use depilatory creams for at least 48 hours prior.",
        "Inform About Skin Conditions: Notify your provider of any existing skin issues."]

    const afterTreatment = [
        "Gentle Skincare: Use mild, fragrance-free cleansers and moisturizers.",
        "Hydrate: Drink plenty of water to keep skin hydrated.",
        "Avoid Sun Exposure: Stay out of direct sunlight and use a broad-spectrum sunscreen.",
        "No Makeup: Avoid makeup for at least 24 hours post-treatment.",
        "Avoid Harsh Treatments: No exfoliating, harsh chemicals, or retinoids for a week.",
        "No Strenuous Activities: Avoid heavy exercise and sweating for 24-48 hours.",
        "Don’t Pick or Scratch: Let any flaking or peeling skin naturally shed."]

  return (
    <div className="mainSectionSkin">
        <StyledEngineProvider injectFirst>
        <AppbarHeader backgroundColor={mainBackgroundColor} textColor={mainFontColor}></AppbarHeader>
        <Toolbar></Toolbar>
        <div className="pricingAndImage">
        <div className="pricingBox">
        
        <h1 style = {{color: `${mainFontColor}`, marginTop: "2rem"}}className="pageTitle">Skin</h1>
        <h2 style = {{color: `${mainFontColor}`}}>Treatment Pricing</h2>
        <h3 style = {{color: `${mainFontColor}`, textAlign:"center", marginBottom:"1rem"}}>Microneedling/Collagen Induction Therapy</h3>

        <TwoTextsComponent text1="Single" text2="$325" textColor={mainFontColor}/>
        <TwoTextsComponent text1="Package of 3" text2="$900" textColor={mainFontColor}/>
        <h3 style = {{color: `${mainFontColor}`, textAlign:"center", marginBottom:"1rem"}}>Vampire Facial</h3>
        <TwoTextsComponent text1="Single" text2="$400" textColor={mainFontColor}/>
        <TwoTextsComponent text1="Package of 3" text2="$1000" textColor={mainFontColor}/>

        </div>
        <br></br>
        <br></br>
        <div className="fillerImageSkin1">
        <img src={require('../../resources/filler_pictures/IMG_0003.jpg')} alt="Injectablesfvf" />
        </div>
        <br></br>
        <br></br>
        </div>


        <AccordianList title="What is microneedling?" listItems={microNeedlingList} backgroundColor= {mainBackgroundColor} textColor = {mainFontColor}></AccordianList>
        <AccordianList title="What are the benefits of microneedling?" listSymbol="circle" listItems={benefitsNeedling} backgroundColor= {mainBackgroundColor} textColor = {mainFontColor}></AccordianList>
        <AccordianList title="Who is a good candidate?" listSymbol="circle" listItems={goodCandidateList} backgroundColor= {mainBackgroundColor} textColor = {mainFontColor}></AccordianList>
        <AccordianList title="What is a vampire facial?" listSymbol="circle" listItems={vampireFacialList} backgroundColor= {mainBackgroundColor} textColor = {mainFontColor}></AccordianList>
        <AccordianList title="Benefits of a vampire facial over regular microneedling" listSymbol="circle" listItems={benefitsVampire} backgroundColor= {mainBackgroundColor} textColor = {mainFontColor}></AccordianList>
        <br></br>

        <div className="secondaryBlock">
        <div className="fillerImageSkin2">
        <img src={require('../../resources/filler_pictures/IMG_0004.jpg')} alt="Injectablesfvf" />
        </div>

        <div className="prePostCarePRP">
            <h2 className="darkFont">
            Pre and Post Care Instructions
            </h2>
            <AccordianList title="Before Treatment" listSymbol="circle" listItems={beforeTreatment} backgroundColor= {mainBackgroundColor} textColor = {mainFontColor}></AccordianList>
            <AccordianList title="After Treatment" listSymbol="circle" listItems={afterTreatment} backgroundColor= {mainBackgroundColor} textColor = {mainFontColor}></AccordianList>
        </div>
        </div>

    </StyledEngineProvider>
    <div className="pageFooterSkin" >
        <Copyright></Copyright>
    </div>
    </div>
  )
};

export default Skin;