import React from "react"
import './Copyright.scss'
import { Typography } from "@mui/material";

function Copyright(props) {

    const copyrightColor = props.color? props.color : "#ffffff";

    return (
      <Typography
        style={{color: {copyrightColor}, paddingBottom: "1rem"}}
        variant="body2"
        color="textSecondary"
        align="center"
      >
        {`Copyright © Halo Beauty Bar ${new Date().getFullYear()}`}
      </Typography>
    );
  }

export default Copyright;