// ContactUs.js

import React, { useState } from 'react';
import './ContactForm.scss';

const ContactForm = (props) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
    
  const handleSubmit = (e) => {

    e.preventDefault();
    // Handle form submission logic here
    console.log('Form submitted:', { name, email, message });
    // You can add your form submission logic here, like sending the data to a server.  
    let formData = new FormData()
        formData.append("form-name", "contact")
        formData.append("name", `${name}`)
        formData.append("email", `${email}`)
        formData.append("message", `${message}`)
  
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => alert("Thank you for your submission"))
      .catch((error) => alert("Seems like there was an error! you can email us at info@halobeautybar.com"));
  };

  const fontColor = props.fontColor?? "#000000";
  const backgroundColor = props.backgroundColor?? "#F3E9E0";
  const buttonColor = props.buttonColor?? "#b98a8a";
  const buttonFontColor = props.buttonFontColor?? "#F3E9E0"

  return (
    <div style={{color:`${fontColor}`, backgroundColor:`${backgroundColor}`}} className="contact-us-container">
      <div className="contact-us-text">
        <h2>Get in touch!</h2>
        <h3>Have questions about our services? We're here to help! Feel free to reach out to us anytime – your inquiries are always welcome.</h3>
      </div>
      <form onSubmit={handleSubmit} method='post'>
        <input type="hidden" name="form-name" value="contact" />
        <label htmlFor="name">Name</label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />

        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />

        <label htmlFor="message">Message</label>
        <textarea
          id="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        ></textarea>

        <button type="submit" 
                style={{backgroundColor:buttonColor,
                        color:buttonFontColor}}>Submit</button>
      </form>
    </div>
  );
};

export default ContactForm;
