import React, { useState, useRef } from "react"
import './Injectables.scss'
import { StyledEngineProvider } from '@mui/material/styles';
import { Toolbar } from "@mui/material";
import TwoTextsComponent from "../../components/Price/TwoTextsComponent";
import AppbarHeader from "../../components/AppbarHeader/AppbarHeader";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Copyright from "../../components/Copyright/Copyright";

function Injectables () {

    const mainBackgroundColor = "#947662";
    const mainTextColor = "#FFFFFF"
    
    const [dermalFillerOpen, setDermalFillerOpen] = useState(false);
    const [botoxOpen, setBotoxOpen] = useState(false);
    const accordianDermal = useRef();
    const accordianBotox = useRef();

    const handleDermalFillerToggle = () => {
        setDermalFillerOpen(!dermalFillerOpen);
        setTimeout(() => {
            accordianDermal.current.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          }, 200);
    };

    const handleBotoxToggle = () => {
        setBotoxOpen(!botoxOpen);
        setTimeout(() => {
            accordianBotox.current.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          }, 200);
    };

  return (
    <div className="mainSection">
        <StyledEngineProvider injectFirst>
        <AppbarHeader backgroundColor= {mainBackgroundColor} textColor = {mainTextColor}></AppbarHeader>
        <Toolbar></Toolbar>
        <h1 className="pageTitle">Injectables Pricing</h1>

        <div className="pricingAndImage">
        <div className="pricingBox" >
        <h2>Neuromodulator</h2>
        <TwoTextsComponent text1="Botox & Dysport (per unit)" text2="$9" textColor="#F3E9E0"/>
        <h2>Filler</h2>
        <div>
        <TwoTextsComponent text1="Quarter syringe" text2="$200" textColor="#F3E9E0"/>
        <TwoTextsComponent text1="Half syringe" text2="$325" textColor="#F3E9E0"/>
        <TwoTextsComponent text1="Full syringe" text2="$525" textColor="#F3E9E0" />
        <TwoTextsComponent text1="Tear Troughs" text2="$575" textColor="#F3E9E0"/>
        <TwoTextsComponent text1="Filler Dissolve" text2="$150" textColor="#F3E9E0"/>
        </div>
        <h2>Lip Flip</h2>
        <TwoTextsComponent text1="Injection fee" text2="$60" textColor="#F3E9E0"/>
        <h2>Hyperhydrosis Treatment*</h2>
        <TwoTextsComponent text1="Injection fee" text2="$200" textColor="#F3E9E0"/>
        <h2>Migraine Treatment*</h2>
        <TwoTextsComponent text1="Injection fee" text2="$200" textColor="#F3E9E0"/>
        <br></br>
        <h4 className="asterisk">*Bring your own prescription Botox!</h4>
        <br></br>
        </div>

        <div className="image1">
        <img src={require('../../resources/filler_pictures/IMG_0002.jpg')} alt="Injectablesfvf" />
        </div>

        </div>
        <div className="prePostCare">
            <h2 className="darkFontInjectables" onClick={handleDermalFillerToggle}>
            Pre and Post Care Instructions
            </h2>
            <Accordion className = "accordianContainer" 
                expanded={dermalFillerOpen}
                TransitionProps={{ timeout: 100 }}>
                <AccordionSummary onClick={handleDermalFillerToggle} expandIcon={<ExpandMoreIcon  />}>
                    <h3 className="darkFontInjectables">Dermal Filler</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="accordianFlexBox">
                        <h3 className="firstHeading">Before</h3>
                        <ul>
                        <h4 className="listItemInjectables"><li style={{listStyleType:"circle"}}>Avoid aspirin, ibuprofen, naproxen, fish oil, etc.</li></h4>
                        <h4 className="listItemInjectables"><li style={{listStyleType:"circle"}}>Notify your provider of any history of cold sores.</li></h4>
                        <h4 className="listItemInjectables"><li style={{listStyleType:"circle"}}>Avoid vaccines, immunizations, procedures, illnesses, or dental work two weeks before and after fillers.</li></h4>
                        </ul>
                        <h3 className="listHeadingInjectables">After</h3>
                        <ul>
                        <h4 className="listItemInjectables"><li style={{listStyleType:"circle"}}>Avoid aspirin, ibuprofen, naproxen, fish oil, etc.</li></h4>
                        <h4 className="listItemInjectables"><li style={{listStyleType:"circle"}}>Notify your provider of any history of cold sores.</li></h4>
                        <h4 className="listItemInjectables"><li style={{listStyleType:"circle"}}>Avoid vaccines, immunizations, procedures, illnesses, or dental work two weeks before and after fillers.</li></h4>
                        </ul>
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion 
                className = "accordianContainer" 
                expanded={botoxOpen} ref={accordianDermal}
                TransitionProps={{ timeout: 100 }}>
                <AccordionSummary onClick={handleBotoxToggle} expandIcon={<ExpandMoreIcon/>}>
                    <h3 className="darkFontInjectables">Botox</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <div className="accordianFlexBox">
                        <h3 className="listHeadingInjectables">Before</h3>
                        <ul>
                        <h4 className="listItemInjectables"><li style={{listStyleType:"circle"}}>To minimize bleeding and bruising, avoid aspirin, ibuprofen (Motrin or Advil), naproxen, Aleve, fish oil, St. John's Wort, Vitamin E, Vitamin D, garlic, Ginkgo Biloba, ginseng, etc.</li></h4>
                        </ul>
                        <h3 className="listHeadingInjectables">After</h3>
                        <ul>
                        <h4 className="listItemInjectables"><li style={{listStyleType:"circle"}}>Do not lay down for 4-6 hours post treatment to avoid migration of Botox.</li></h4>
                        <h4 className="listItemInjectables"><li style={{listStyleType:"circle"}}>Avoid extreme temperatures and rigorous exercise for at least 48 hours.</li></h4>
                        <h4 className="listItemInjectables"><li style={{listStyleType:"circle"}}>Do not touch, massage or manipulate the injection sites.</li></h4>
                        <h4 className="listItemInjectables"><li style={{listStyleType:"circle"}}>Continue avoiding all blood thinners for at least 48 hours.</li></h4>
                        <h4 className="listItemInjectables" ref={accordianBotox}><li style={{listStyleType:"circle"}}>Tylenol is a great alternative to Ibuprofen if experiencing pain. If pain is severe, please notify provider.</li></h4>
                        </ul>
                    </div>
                </AccordionDetails>
            </Accordion> 
        </div>

        <div className="pageFooterInjectables" >
            <Copyright></Copyright>
        </div>
    </StyledEngineProvider>
    </div>
  )
};

export default Injectables;