import React, { useState, useRef } from "react"
import './AccordianList.scss'
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function AccordianList(props) {

    const [openAccordian, setOpenAccordian] = useState(false);
    let accordianRef = useRef();
    
    const handleAccordianToggle = () => {
        
        setOpenAccordian(!openAccordian);
        
        setTimeout(() => {
            accordianRef.current.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          }, 200);
    };

    return (
        <div className="accordianComponent">
        <Accordion 
            sx={{backgroundColor:`${props.backgroundColor}`, marginLeft:"0.5rem", marginRight:"0.5rem"}}
            expanded={openAccordian}
            TransitionProps={{ timeout: 200 }}>
            <AccordionSummary onClick={handleAccordianToggle} expandIcon={<ExpandMoreIcon  />}>
                <div className="horizontalTexts">
                <h3 style = {{color: `${props.textColor}`}}>{props.title}</h3>
                <h3 style = {{color: `${props.textColor}`}}>{props.title2}</h3>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div className="accordianFlexBox">
                    <ul className="listBox">
                        {props.listItems.map(value => (
                            <h4 style = {{color: `${props.textColor}`}} className="listItem" key={value}>
                                <li style={{listStyleType:`${props.listSymbol?? "none"}`}}>{value}</li>
                            </h4>
                        ))}
                    </ul>
                    <div></div>
                </div>
            </AccordionDetails>
        </Accordion>
        <div ref={accordianRef}></div>
        </div>
    )
}
