// ContactPage.js

import React from 'react';
import './ContactPage.scss';
import { Toolbar } from "@mui/material";
import AppbarHeader from "../../components/AppbarHeader/AppbarHeader";
import MapBox from '../../components/Map/MapBox';
import { StyledEngineProvider } from '@mui/material/styles';
import ContactForm from '../../components/Contact/ContactForm';
import Copyright from '../../components/Copyright/Copyright';

const ContactPage = () => {

  const mainBackgroundColor = "#F3E9E0";
  const mainFontColor = "#000000"
  //const buttonColor = "#9B0C3E"

  const locationHours = [
    { day: 'Monday', hours: '10AM - 6PM' },
    { day: 'Tuesday', hours: '10AM - 8PM' },
    { day: 'Wednesday', hours: '10AM - 8PM' },
    { day: 'Thursday', hours: '10AM - 8PM' },
    { day: 'Friday', hours: '10AM - 6PM' },
    { day: 'Saturday', hours: '10AM - 6PM' },
    { day: 'Sunday', hours: 'Closed' },

  ];

  return (
    <StyledEngineProvider injectFirst>

    <div className="contact-page">
      <AppbarHeader backgroundColor={mainBackgroundColor} textColor={mainFontColor}></AppbarHeader>
      <Toolbar></Toolbar>
      <div className="contact-info">
        <div className='contactLandingImage'>
        <img src={require('../../resources/filler_pictures/IMG_0023.jpg')} alt="LocationImage" />
        </div>
        <div className="location">
          <h2>Our Location</h2>
            <div className="addressLocation">
            <h4>1014 1 St SE Studio #1 </h4>
            <h4>Calgary, AB T2G 2G5</h4>
            </div>
          <h2>Parking</h2>
            <h4>Underground parking is located in the UPTEN building. Ample street parking is located on 10th Ave SE and adjacent paid lot across the street from UPTEN</h4>

        </div>
        <div className="location-hours">
          <h2>Location Hours</h2>
          <ul>
            {locationHours.map((item) => (
              <li key={item.day}>
                <h4>{`${item.day}: ${item.hours}`}</h4>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className='mapBox'>
        <MapBox></MapBox>
      </div>

      <div className="contact-details">
        <h2>Contact Details</h2>
        <h4>
          <strong>Call Us:</strong> +1 (368) 886-3578
        </h4>
        <h4>
          <strong>Email Us:</strong> info@halobeautybar.com
        </h4>
      </div>
    </div>
    
    <div style={{backgroundColor: `${mainBackgroundColor}`}}>
      <ContactForm 
        backgroundColor = {mainBackgroundColor}
        fontColor = {mainFontColor}></ContactForm>
    </div>

    <div className="pageFooterTeam">
      <Copyright></Copyright>
    </div>
    </StyledEngineProvider>
  );
};

export default ContactPage;
