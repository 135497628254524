import './App.css';
import {Routes, Route} from 'react-router-dom';
import Home from './pages/Home/Home';
import Injectables from './pages/Injectables/Injectables.js';
import Skin from './pages/Skin/Skin';
import PRP from './pages/PRP/PRP';
import Academy from './pages/Academy/Academy';
import Team from './pages/Team/Team.js';
import ContactPage from './pages/Contact/ContactPage.js';

function App() {
  return (
    <div>
        <Routes>
          <Route path = "/" element = {<Home/>}/>
          <Route path = "/injectables" element = {<Injectables/>}/>
          <Route path = "/skin" element = {<Skin/>}/>
          <Route path = "/prp" element = {<PRP/>}/>
          <Route path = "/academy" element = {<Academy/>}/>
          <Route path = '/team' element = {<Team/>}/>
          <Route path = '/contact' element = {<ContactPage/>}/>

        </Routes>
    </div>
  );
}

export default App;
