import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import './AppbarHeader.scss';
import IconButton from '@mui/material/IconButton';
import CloseIcon from "@mui/icons-material/Close";
import { AppBar, Button, StyledEngineProvider, Toolbar } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import MenuComponent from "../Menu/MenuComponent";
import HaloLogo from "../../resources/Halo";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default function AppbarHeader(props) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    //const [appBarHeight, setAppBarHeight] = useState(0);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const navigate = useNavigate();
  
    // useEffect(() => {
    //   // Dynamically calculate the height of the AppBar
    //   const appBar = document.getElementById("appBar1");
    //   if (appBar) {
    //     setAppBarHeight(appBar.clientHeight);
    //   }
    // }, []);
  
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
      if (isDrawerOpen) {
        setIsDrawerOpen(false);
      }
    };
  
    const handleMenuClick = (path) => {
        if (path !== "/services")
        {
            navigate(path);
            setIsMenuOpen(false);
            setIsDrawerOpen(false); 
        }
    };
  
    return (
      <div>
        {/* Use MenuComponent here */}

        {window.screen.width < 1440 ?
        
        <div>
          <MenuComponent
          isOpen={isMenuOpen}
          onClose={() => setIsMenuOpen(false)}
          onItemClick={handleMenuClick}        
          />

          <AppBar id="appBar1" position="absolute" elevation={0}>
            <StyledEngineProvider injectFirst>
              <Toolbar className="appBar" sx={{backgroundColor:`${props.backgroundColor}`}}>
                <IconButton onClick={toggleMenu} sx={{color:`${props.textColor}`}}>
                  {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
                </IconButton>
                <a className="appBarName" onClick={() => handleMenuClick("/")} href="/">
                  {/* <text style={{color:`${props.textColor}`}}>Halo Beauty Bar</text> */}
                  <div className="headerLogo">
                    <HaloLogo color = {props.textColor}></HaloLogo>
                  </div>
                </a>

                <a
                  className="appBarInsta"
                  href="https://www.instagram.com/halobeautybar_ab/"
                >
                  <InstagramIcon sx={{color: `${props.textColor}`}}/>
                </a>
              </Toolbar>
            </StyledEngineProvider>
          </AppBar>
        </div>
        :
        <div>
          <AppBar id="appBar1" position="absolute" elevation={0}>
            <StyledEngineProvider injectFirst>
              <Toolbar className="appBar" sx={{backgroundColor:`${props.backgroundColor}`}}>

                <a className="appBarName" onClick={() => handleMenuClick("/")} href="/">
                    {/* <text style={{color:`${props.textColor}`}}>Halo Beauty Bar</text> */}
                    <div className="headerLogo">
                      <HaloLogo color = {props.textColor}></HaloLogo>
                    </div>
                </a>

                <Button
                className="meetTheTeam"
                sx={{margin:"2rem", backgroundColor:`${props.backgroundColor}`, color:`${props.textColor}`}} 
                variant="text"
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                >Services</Button>

                <Menu
                  className="openMenu"
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  sx={{"& .MuiMenu-paper": { backgroundColor: `${props.backgroundColor}`},
                        "& .MuiPopover-paper" : {color:`${props.textColor}`, fontFamily: "TitleFont"}}}
                >
                  <MenuItem style={{ fontSize: "25px", fontFamily: "TitleFont" }} className = "menuItem" onClick={() => handleMenuClick("/injectables")}>Injectables</MenuItem>
                  <MenuItem style={{ fontSize: "25px", fontFamily: "TitleFont" }} className = "menuItem" onClick={() => handleMenuClick("/skin")}>Skin</MenuItem>
                  <MenuItem style={{ fontSize: "25px", fontFamily: "TitleFont" }} className = "menuItem" onClick={() => handleMenuClick("/prp")}>PRP</MenuItem>

                </Menu>
                
                <Button
                className="meetTheTeam"
                onClick={() => handleMenuClick("/team")} 
                sx={{margin:"2rem", backgroundColor:`${props.backgroundColor}`, color:`${props.textColor}`}} 
                variant="text"
                >Team</Button>

                <Button
                className="meetTheTeam"
                onClick={() => handleMenuClick("/academy")} 
                sx={{margin:"2rem", backgroundColor:`${props.backgroundColor}`, color:`${props.textColor}`}} 
                variant="text"
                >Academy</Button>

                <Button
                className="meetTheTeam"
                onClick={() => handleMenuClick("/contact")} 
                sx={{margin:"2rem", backgroundColor:`${props.backgroundColor}`, color:`${props.textColor}`}} 
                variant="text"
                >Contact</Button>

                <Button
                className="meetTheTeam"
                href="https://halobeautybar.janeapp.com/" 
                sx={{margin:"2rem", backgroundColor:`${props.backgroundColor}`, color:`${props.textColor}`}} 
                variant="text"
                >Book</Button>

                <div className="headerSpacer">
                  <a
                    className="appBarInsta"
                    href="https://www.instagram.com/halobeautybar_ab/"
                  >
                    <InstagramIcon sx={{color: `${props.textColor}`}}/>
                  </a>
                </div>
              </Toolbar>
            </StyledEngineProvider>
          </AppBar>
        </div>
          }
      </div>
    );
  }