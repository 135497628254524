import React from "react"
import './Team.scss'
import { StyledEngineProvider } from '@mui/material/styles';
import { Toolbar } from "@mui/material";
import AppbarHeader from "../../components/AppbarHeader/AppbarHeader";
import UseIntersectionObserver from "../../components/UserIntersectionObserver";
import Button from "@mui/material/Button";
import ContactForm from "../../components/Contact/ContactForm";
import Copyright from "../../components/Copyright/Copyright";

function Team () {

    // const { targetRef, isVisible } = UseIntersectionObserver(null, {
    //     root: null,
    //     rootMargin: '0px',
    //     threshold: 0.01, // Adjust this threshold as needed
    //   });

    const { targetRef:targetRef2, isVisible:isVisible2 } = UseIntersectionObserver(null, {
    root: null,
    rootMargin: '0px',
    threshold: 0.01, // Adjust this threshold as needed
    });

    const mainBackgroundColor = "#F3E9E0";
    const mainFontColor = "#000000"
    const buttonColor = "#b98a8a"

  return (
    <div className="mainSectionTeam">
        <StyledEngineProvider injectFirst>
        <AppbarHeader backgroundColor={mainBackgroundColor} textColor={mainFontColor}></AppbarHeader>
        <Toolbar></Toolbar>

        <div className="fillerImageAcademy">
        <img src={require('../../resources/filler_pictures/IMG_0419.JPG')} alt="Injectablesfvf" />
        </div>

        <h1 className="pageHeaderTeam">Meet the Halo Family</h1>

        <div ref={targetRef2} className={`TeamComponent ${isVisible2 ? 'visible' : ''}`}>
            <div className="teamMemberInfoBox">
                <div className="teamMemberImage">
                    <img src={require('../../resources/filler_pictures/IMG_0486.JPG')} alt="Injectablesfvf"/>
                </div>
                <h2 className="nameTeamMember">Michelle</h2>
                <div className="positionTeamMember">
                    <div className="qulificationsTeamMember">
                        <h4> Michelle is the founder of Halo Beauty Bar, a clinic where uncompromising quality in medical aesthetics is paired with client centricity. With a background in Psychology and Nursing from the University of Calgary, Michelle  blends her academic knowledge with a genuine passion for medical aesthetics. With a  real dedication to teaching and sharing her expertise in the field, Michelle is focused on building up the competency of her team, while extending her own knowledge in this ever changing field. Her ability to fuse client beauty goals with her own unique personal touch has made Halo Beauty Bar a go-to destination for those seeking quality and care in cosmetic treatments.</h4>
                    </div>
                </div>
                <Button
                    className="bookNowButton"
                    href="https://halobeautybar.janeapp.com/#/staff_member/1" 
                    sx={{margin:"1rem", backgroundColor:`${buttonColor}`}} 
                    variant="contained"
                    >Book Now</Button>
            </div>

            <div className="teamMemberInfoBox">
                <div className="teamMemberImage">
                    <img src={require('../../resources/filler_pictures/IMG_0461.JPG')} alt="Injectablesfvf"/>
                </div>
                <h2 className="nameTeamMember">Drew</h2>
                <div className="positionTeamMember">
                    <div className="qulificationsTeamMember">
                        <h4>With a Bachelor of Nursing at Mount Royal University, Drew has primarily worked in pediatric mental health at the Alberta Children’s Hospital. She began her career in aesthetics injectables in 2022 and has received extensive training in Calgary, including the opportunity to advance her knowledge through an internship at Halo Beauty Bar under the mentorship of founder Michelle Quazi. <br></br>Drew is devoted to creating stunning yet natural results for her clients by combining medical knowledge with an artistic touch. <br></br>When she’s not injecting, you can find her spending time in the mountains with her German Shepherd, Frankie or as a cheerleader for the Calgary Stampeders</h4>
                    </div>
                </div>
                <Button
                    className="bookNowButton"
                    href="https://halobeautybar.janeapp.com/#/staff_member/4" 
                    sx={{margin:"1rem", backgroundColor:`${buttonColor}`}} 
                    variant="contained"
                    >Book Now</Button>
            </div>

            <div className="teamMemberInfoBox">
                <div className="teamMemberImage">
                    <img src={require('../../resources/filler_pictures/IMG_0454.jpg')} alt="Injectablesfvf"/>
                </div>
                <h2 className="nameTeamMember">Priscilla</h2>
                <div className="positionTeamMember">
                    <div className="qulificationsTeamMember">
                        <h4>Priscilla obtained her Bachelor of Nursing from Mount Royal University, and has worked as a registered nurse for 7 years with a specialization in psychiatry. Her passion for aesthetics led to the expansion of her career as a cosmetic injector at Halo Beauty Bar. She is a firm believer that self-care and self-love directly correlate to confidence and mental well-being.
                            <br></br>
                            Natural Rejuvenation is her aesthetic approach. She is here to collaborate with you to enhance your natural beauty so you leave each appointment feeling more confident!
                        </h4>
                    </div>
                </div>
                <Button
                    className="bookNowButton"
                    href="https://halobeautybar.janeapp.com/#/staff_member/5" 
                    sx={{margin:"1rem", backgroundColor:`${buttonColor}`}} 
                    variant="contained"
                    >Book Now</Button>
            </div>
            
            </div>

        <div className="fillerImageTeam">
        <img src={require('../../resources/filler_pictures/IMG_0048.jpg')} alt="Injectablesfvf" />
        </div>

        <ContactForm></ContactForm>

    </StyledEngineProvider>
    <div className="pageFooterTeam">
     <Copyright></Copyright>

    </div>
    </div>
  )
};

export default Team;