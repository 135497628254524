import { Toolbar } from "@mui/material";
import { StyledEngineProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import React from "react"
import './Home.scss'
import AppbarHeader from "../../components/AppbarHeader/AppbarHeader";
import Button from "@mui/material/Button";
import ContactForm from "../../components/Contact/ContactForm";
import UseIntersectionObserver from "../../components/UserIntersectionObserver";
import MapBox from "../../components/Map/MapBox";
import Gallery from "../../components/Gallery/Gallery";
import ReactCurvedText from 'react-curved-text';
import ShadowText from "../../components/ShadowText/ShadowText";
import Copyright from "../../components/Copyright/Copyright";

function Home () {

  const navigate = useNavigate();

  const screenWidth = window.screen.width;

  const handleMenuClick = (path) => {
    window.scrollTo(0, 0);
    navigate(path);
  };


  const headerColor = "#e79796"
  const headerTextColor = "#F3E9E0"
  const primaryBackgroundColor = "#e79796";
  const primaryTextColor = "#F3E9E0";
  const buttonColor = "#b98a8a"

  const { targetRef:targetRef2, isVisible:isVisible2 } = UseIntersectionObserver(null, {
    root: null,
    rootMargin: '0px',
    threshold: 0.01, // Adjust this threshold as needed
    });

  const { targetRef:targetRef3, isVisible:isVisible3 } = UseIntersectionObserver(null, {
    root: null,
    rootMargin: '0px',
    threshold: 0.01, // Adjust this threshold as needed
    });

  const { targetRef, isVisible } = UseIntersectionObserver(null, {
    root: null,
    rootMargin: '0px',
    threshold: 0.1, // Adjust this threshold as needed
    });
  
  const { targetRef:targetRef4, isVisible:isVisible4 } = UseIntersectionObserver(null, {
    root: null,
    rootMargin: '0px',
    threshold: 0.03, // Adjust this threshold as needed
    });


  const services = ["Injectables", "Skin", "PRP"]

  return (
    <div className = "home-page-main">
      <StyledEngineProvider injectFirst>
      <AppbarHeader
        backgroundColor={headerColor}
        textColor = {headerTextColor}></AppbarHeader>
      <Toolbar></Toolbar>
      <div ref={targetRef2} className={`introduction ${isVisible2? "visible": ""}`}>

        {/* <h1> Welcome to Halo Beauty Bar!</h1> */}
          <div>
          <div className="circularText">
          <ReactCurvedText
            width={300}
            height={300}
            cx={150}
            cy={150}
            rx={160}
            ry={150}
            startOffset={0}
            reversed={true}
            text="Your Beauty Goals Realized. Expertly Injected. Halo Beauty Bar"
            textProps={{ style: { fontSize: 17 , fill:`${primaryTextColor}`} }}
            textPathProps={null}
            tspanProps={null}
            ellipseProps={null}
            svgProps={{overflow:"visible"}}
        />
        
        </div>
        <div className="landingImageHome">
            <img src={require('../../resources/filler_pictures/IMG_Home_grey.jpg')} alt="homepagearch"/>
        </div>
        </div>

        <div className="leftsideBox">

        <div className="ShadowText">
        <ShadowText 
          backgroundColor = {primaryBackgroundColor}
          fontColor = {primaryTextColor}></ShadowText>
        </div>

        {window.screen.width > 481?  

         <div className="description">
          <h4> Injectables </h4>
          <h4>Botox</h4>
          <h4>Dermal Filler</h4>
          <h4>PRP</h4>
          <br></br>
          <h4>+1(368)886-3578</h4>
          <h4>info@halobeautybar.com</h4>
        </div> 
        :  
        null}
       </div>
      </div>


      <div className="horizontalFlexHome">
        <div className="fillerImageHome">
          <img src={require('../../resources/filler_pictures/IMG_0048.jpg')} alt="Injectablesfvf" />
        </div>
        <div className="horizontalTextHome">
          <h1>You. Us. Beautiful Results</h1>
          <h4>At Halo YOU are always top of mind. Come in and see the difference that passion and expertise in cosmetic injectables can do for your beauty goals. We look forward to meeting you!</h4>
          
          {screenWidth >= 1024 ? 
            <Button
            className="meetTheTeam"
            onClick= {() => handleMenuClick("/team")}
            sx={{margin:"2rem", 
                  backgroundColor:`${buttonColor}`}} 
            variant="contained"
            >Meet The Team</Button>
            :
            null
          }

        </div>
      </div>
      { screenWidth < 1024 ?
      <div ref = {targetRef3} className={`secondBlock ${isVisible3? "visible":""}`}>

        <div className="fillerImageHome">
          <img src={require('../../resources/filler_pictures/Team.jpg')} alt="Injectablesfvf" />
        </div>

        <Button
          className="meetTheTeam"
          onClick= {() => handleMenuClick("/team")}
          sx={{margin:"2rem", backgroundColor:`${buttonColor}`}} 
          variant="contained"
          >Meet The Team</Button>
      </div>

      :
      null
      }

      <div ref = {targetRef} className={`thirdBlock ${isVisible?"visible":""}`}>

        <h2> We've got you covered</h2>
        <div className="servicesBlock">
        {services.map((value) => (
          <div key={value} className="serviceCard">
            <h2>{value}</h2>
            <Button
            className="meetTheTeam"
            onClick={() => handleMenuClick(`/${value}`)} 
            sx={{margin:"2rem", 
                backgroundColor:`${buttonColor}`,
                }} 
            variant="contained"
            >Our Services</Button>
          </div>
        ))}
        </div>

        <div className="fillerImageHome">
          <img src={require('../../resources/filler_pictures/IMG_0039.jpg')} alt="Injectablesfvf" />
        </div>
      </div>

      <div ref = {targetRef4} className={`fourthBlock ${isVisible4?"visible":""}`}>
        <div className="wideScreen">
          <div className="locationHome">
            <h2>Our Location</h2>
            <div className="addressLocation">
            <h4>1014 1 St SE Studio #1 </h4>
            <h4>Calgary, AB T2G 2G5</h4>
            </div>
            <MapBox></MapBox>
          </div>

          <h2>See you soon!</h2>
          
          <Button
            className="meetTheTeam"
            href="https://halobeautybar.janeapp.com/" 
            sx={{margin:"2rem", backgroundColor:`${buttonColor}`}} 
            variant="contained"
            >Book Appointment</Button>
        </div>

        {window.screen.width < 1440?
        <div className="fillerImageHome">
          <img src={require('../../resources/filler_pictures/IMG_0522.JPEG')} alt="Injectablesfvf" />
        </div>
        :
        null
        }

        <ContactForm
          buttonColor = {buttonColor}
          ></ContactForm>
      </div>
      
      <div className="fifthBlock">
        <h2>Check out our recent work!</h2>

        <Gallery></Gallery>
      </div>

      <div className="pageFooterHome" >
        <Copyright></Copyright>
      </div>
      
      </StyledEngineProvider>
    </div>
  )
};

export default Home;
