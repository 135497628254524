import React , { useState, useEffect } from "react";
import './MenuComponent.scss';
import IconButton from '@mui/material/IconButton';
import CloseIcon from "@mui/icons-material/Close";
import { List, ListItem, Drawer, StyledEngineProvider } from '@mui/material';
import HaloLogo from "../../resources/Halo";


const MenuComponent = ({ isOpen, onClose, onItemClick }) => {
  
  //var appbarbackground = "#9B0C3E";
  var appbarFontColor = "#F3E9E0";
  
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [appBarHeight, setAppBarHeight] = useState(0);


    useEffect(() => {
        // Dynamically calculate the height of the AppBar
        const appBar = document.getElementById("menuHeader");
        if (appBar) {
          setAppBarHeight(appBar.clientHeight);
        }
        document.body.style.overflow = 'unset';
        if (isOpen) {
        document.body.style.overflow = 'hidden';
        }
      }, [isOpen, appBarHeight]);

    
    
    const handleMenuClick = (path) => {    
        if(isOpen)
        {
        if (path === '/services') {
            // Open the drawer for "Services"
            setIsDrawerOpen(true);
        } 
        else
        {
            if (isDrawerOpen)
            {
                toggleDrawer();
            }
            onClose();
        }
        onItemClick(path);
        }
    };

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
      };
  
    return (
      <div>
        <div className={`menuContainer ${isOpen ? "fadeIn" : "fadeOut"}`}>
        <div id="menuHeader" className="menuHeader">
            <div className="menuCloser">
                <IconButton onClick={onClose}>
                    <CloseIcon sx={{color:`${appbarFontColor}`}} />
                </IconButton>
            </div>
            {/* <text onClick = {() => handleMenuClick("/")} className="menuTitle">Halo Beauty Bar</text> */}
            <div onClick = {() => handleMenuClick("/")} className="menuTitle">
                  <HaloLogo color = {appbarFontColor}></HaloLogo>
            </div>

        </div>

        <div className="menu">
          <h1 onClick={() => handleMenuClick("/services")}>Services</h1>
          <h1 onClick={() => handleMenuClick("/academy")}>Academy</h1>
          <h1 onClick={() => handleMenuClick("/team")}>Team</h1>
          <h1 onClick={() => handleMenuClick("/contact")}>Contact</h1>
          {isOpen? <a href="https://halobeautybar.janeapp.com/" className="bookNowLink">
            <h1>Book Now</h1>
          </a> : null }
        </div>
        <StyledEngineProvider injectFirst>
        <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        elevation={0}
        sx={{
          '&.MuiDrawer-root .MuiDrawer-paper': {
            width: '100%',
            paddingTop: "50%",
            marginTop: `${appBarHeight}px`,
            backgroundColor: "#b98a8a",
          },
        }}
        slotProps={{
          backdrop: { style: { backgroundColor: 'rgba(255,255,255,0)' } },
        }}
        >
        <List className="drawerList">
          <ListItem className="drawerListItem" button onClick={() => handleMenuClick('/injectables')}>
            <h1>Injectables</h1>
          </ListItem>
          <ListItem className="drawerListItem" button onClick={() => handleMenuClick('/skin')}>
            <h1>Skin</h1>
          </ListItem>
          <ListItem className="drawerListItem" button onClick={() => handleMenuClick('/prp')}>
            <h1>Platelet-Rich-Plasma</h1>
          </ListItem>
        </List>
        </Drawer>
        </StyledEngineProvider>
      </div>
      </div>
    );
  };
  
  export default MenuComponent;