import React from "react"
import './PRP.scss'
import { StyledEngineProvider } from '@mui/material/styles';
import { Toolbar } from "@mui/material";
import TwoTextsComponent from "../../components/Price/TwoTextsComponent";
import AppbarHeader from "../../components/AppbarHeader/AppbarHeader";
import AccordianList from "../../components/AccordianList/AccordianList";
import Copyright from "../../components/Copyright/Copyright";

function PRP () {

    const mainBackgroundColor = '#e79796';
    const mainFontColor = '#000000';
    const secondaryColor = '#F3E9E0';
    const secondaryFontColor = '#000000';

    const prpBenefits= [ "Skin Rejuvenation: Improves skin texture and tone.",
        "Wrinkle Reduction: Diminishes fine lines and wrinkles.",
        "Scar Treatment: Effective in reducing scar appearance.",
        "Boosts Collagen: Stimulates collagen production for firmer skin.",
        "Hair Growth: Aids in hair restoration and thickness.",
        "Natural Approach: Uses body's own growth factors, reducing allergy risks.",
        "Faster Healing: Accelerates healing when combined with other treatments.",
        "Safe and Minimally Invasive: Less invasive than surgical options.",
        "Customizable: Tailored to individual needs.",
        "Durable Results: Offers long-lasting effects.",]
    
    const prpResults = [
        "Initial Improvement: Some individuals may notice initial improvements in skin texture and tone within a few days to a week after the treatment.",
        "Visible Changes: More significant and visible results usually start to become apparent after a few weeks, as the body's natural healing process and collagen production are stimulated.",
        "Peak Results: The full effects of PRP treatments are often seen after a few months, usually around 3 to 6 months post-treatment. This is when collagen maturation and skin rejuvenation are more pronounced.",
        "Multiple Sessions: For optimal results, especially in cosmetic applications like skin rejuvenation or hair loss treatment, multiple PRP sessions may be recommended. The full effect is typically seen after the completion of the suggested treatment course.",
        "Individual Variability: Results can vary based on the individual's age, skin condition, overall health, and the specific area being treated."]
    
    const beforeTreatment = [
        "Stop Harsh Skin Treatments: Avoid retinoids and exfoliants 3 days prior to treatment.",
        "No Laser or Peels: Refrain from these treatments 2 weeks before microneedling.",
        "Minimize Sun Exposure: Stay out of the sun for a week before and use sunscreen.",
        "Clean, Hydrated Skin: Ensure your skin is free of active infections or inflammations.",
        "No Waxing: Do not wax or use depilatory creams for at least 48 hours prior.",
        "Inform About Skin Conditions: Notify your provider of any existing skin issues."]

    const afterTreatment = [
        "Gentle Skincare: Use mild, fragrance-free cleansers and moisturizers.",
        "Hydrate: Drink plenty of water to keep skin hydrated.",
        "Avoid Sun Exposure: Stay out of direct sunlight and use a broad-spectrum sunscreen.",
        "No Makeup: Avoid makeup for at least 24 hours post-treatment.",
        "Avoid Harsh Treatments: No exfoliating, harsh chemicals, or retinoids for a week.",
        "No Strenuous Activities: Avoid heavy exercise and sweating for 24-48 hours.",
        "Don’t Pick or Scratch: Let any flaking or peeling skin naturally shed.",
        ]

  return (
    <div className="mainSectionPRP">
        <StyledEngineProvider injectFirst>
        <AppbarHeader backgroundColor={mainBackgroundColor} textColor={mainFontColor}></AppbarHeader>
        <Toolbar></Toolbar>
        <h1 style = {{color: `${mainFontColor}`, marginTop: '3rem'}}className="pageTitlePRP">Platelet-rich plasma</h1>

        <div className="prpDefintionBlock">
        <h2>What is PRP?</h2>
        <h4>
            Platelet-rich plasma (PRP) is a concentrated form of plasma, a component of blood, that is rich in platelets. In cosmetic medicine, PRP is often used for skin rejuvenation (like in vampire facials), hair loss treatment, and other anti-aging procedures.
        </h4>
        </div>

        <br></br>

        <div className="pricingAndImagePRP">
        
        <div className="fillerImageSkin">
        <img src={require('../../resources/filler_pictures/IMG_0130.jpg')} alt="Injectablesfvf" />
        </div>

        <div className="pricingBox" >
        <h2 style = {{color: `${mainFontColor}`}}>Services we offer!</h2>

        <h3 style = {{color: `${mainFontColor}`, textAlign:"center"}}>under eyes</h3>
        <TwoTextsComponent text1="Single" text2="$425" textColor={mainFontColor}/>
        <TwoTextsComponent text1="Package of 3" text2="$1000" textColor={mainFontColor}/>

        <h3 style = {{color: `${mainFontColor}`, textAlign:"center"}}>hair</h3>
        <TwoTextsComponent text1="Single" text2="$425" textColor={mainFontColor}/>
        <TwoTextsComponent text1="Package of 3" text2="$1000" textColor={mainFontColor}/>

        <br></br>

        </div>
        </div>

        <AccordianList title="What are the benefits?" listSymbol="circle" listItems={prpBenefits} backgroundColor= {secondaryColor} textColor = {secondaryFontColor}></AccordianList>
        <AccordianList title="When will I see results?" listSymbol="circle" listItems={prpResults} backgroundColor= {secondaryColor} textColor = {secondaryFontColor}></AccordianList>


        <br></br>
        <div className="secondaryBlockPRP">
        <div className="fillerImagePRP">
        <img src={require('../../resources/filler_pictures/IMG_0039.jpg')} alt="Injectablesfvf" />
        </div>

        <div className="prePostCarePRP">
            <h2 className="darkFontPRP">
            Pre and Post Care Instructions
            </h2>
            <AccordianList title="Before Treatment" listSymbol="circle" listItems={beforeTreatment} backgroundColor= {mainBackgroundColor} textColor = {mainFontColor}></AccordianList>
            <AccordianList title="After Treatment" listSymbol="circle" listItems={afterTreatment} backgroundColor= {mainBackgroundColor} textColor = {mainFontColor}></AccordianList>
        </div>
        </div>
    </StyledEngineProvider>
    <div className="pageFooterPRP">
        <Copyright></Copyright>
    </div>
    </div>
  )
};

export default PRP;