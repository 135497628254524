import React from "react"
import './ShadowText.scss'


export default function ShadowText(props) {

    const colorStyle = {
        backgroundColor: props.backgroundColor?? "#000000",
        color: props.fontColor?? "#ffffff"
    }

    return (
        <div className="shadowContainer" >
            <div className="firstShadow" style={colorStyle}>
                <h2>Halo Beauty Bar</h2>
            </div>
            <div className="secondShadow" style={colorStyle}>
                <h2>Halo Beauty Bar</h2>
            </div>
            <div className="thirdShadow" style={colorStyle}>
                <h2>Halo Beauty Bar</h2>
            </div>
            <div className="fourthShadow" style={colorStyle}>
                <h2>Halo Beauty Bar</h2>
            </div>

        </div>
    )
}